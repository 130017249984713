import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BACKEND_MDM_URL, BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import {EQUIPMENT_CALIBRATION, FIELD_ITEMS_VERIFY, LIMS_FIELD_ITEMS, TASK_CALIBRATION_STATUS, USER_EQUIPMENT, USER_FDC_FIELDS} from './urls';

export const createEquipCalibration = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createEquipCalibration',
	async (params, thunkApi) => {
	  try {
		const response = await axios.post(`${BACKEND_MDM_URL}${EQUIPMENT_CALIBRATION}`, params);
		const data = await response.data;
		return data;
	  } catch (err) {
		const msg = err?.response?.data?.message ||  err?.message
		thunkApi.dispatch(showMessage({message: `${msg}`, variant: 'error'}));
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getCalibrationStatus = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getCalibrationStatus',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_SCHEDULER_URL}${TASK_CALIBRATION_STATUS}`, {params});
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getLastCalibrationData = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getLastCalibrationData',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}/lab-equipment/${params.id}/calibration`);
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getUserFdcFields = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getUserFdcFields',
	async (params, thunkApi) => {
	  try {
		const payload = { date: moment().startOf('day').toISOString() }
		const response = await axios.get(`${BACKEND_SCHEDULER_URL}${USER_FDC_FIELDS}`, {params: payload});
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getLimsFieldItems = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getLimsFieldItems',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_SCHEDULER_URL}${LIMS_FIELD_ITEMS}`, {params});
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const updateUserFieldItems = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateUserFieldItems',
	async (params, thunkApi) => {
	  try {
		const response = await axios.post(`${BACKEND_MDM_URL}${USER_EQUIPMENT}`, params);
		const data = await response.data;
		thunkApi.dispatch(getVerifiedUserFieldItems({email: params.email, startOfDayInUTC: moment().startOf('day').toISOString()}));
		return data;
	  } catch (err) {
		const msg = err?.response?.data?.message ||  err?.message
		thunkApi.dispatch(showMessage({message: `${msg}`, variant: 'error'}));
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const verifyUserFieldItems = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/verifyUserFieldItems',
	async (params, thunkApi) => {
	  try {
		const response = await axios.post(`${BACKEND_MDM_URL}${FIELD_ITEMS_VERIFY}`, params);
		const data = await response.data;
		return data;
	  } catch (err) {
		const msg = err?.response?.data?.message ||  err?.message
		thunkApi.dispatch(showMessage({message: `${msg}`, variant: 'error'}));
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getVerifiedUserFieldItems = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getVerifiedUserFieldItems',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}${FIELD_ITEMS_VERIFY}`, {params});
		const data = await response.data;
		return data;
	  } catch (err) {
		const msg = err?.response?.data?.message ||  err?.message
		thunkApi.dispatch(showMessage({message: `${msg}`, variant: 'error'}));
		return thunkApi.rejectWithValue(err);
	  }
	}
);