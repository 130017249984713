import moment from 'moment';
import _ from 'lodash';
import { BlankGridFilterParamText, FILTER_VALUE_BACKEND_MAP } from 'app/main/constants/taskBoardConstants';

export function capitalizeWords(str) {
	if (str && typeof str === "string") {
		return str
			.split(' ')
			.map(word => word.charAt(0).toUpperCase() + (word.slice(1)?.toLowerCase() ?? ''))
			.join(' ');
	}
	return str;
}

export function formatWords(str, fullCaps = false) {
	if (str && typeof str === "string") {
		if (fullCaps) {
			return str.toUpperCase();
		}
		return capitalizeWords(str)
	}
	return str
}

export function getDigitCount(number) {
	if(_.isNumber(number)) return number.toString().length;
	return 1
}

export function checkIsMidnight(date) {
	if (!date) return false;
	const dateObject = moment.utc(date).local().toDate();
	// Get the hours and minutes from the date object
	const hours = dateObject.getHours();
	const minutes = dateObject.getMinutes();
	// Check if the time is 12 AM (midnight)
	return hours === 0 && minutes === 0;
}

export function deepCompareWithConditions(obj1, obj2) {
	const commonSkip = ['actionType', 'id', 'createdOn', 'description']; // description for route
	const keys1 = Object.keys(obj1).filter(k => !commonSkip.includes(k));
	const keys2 = Object.keys(obj2).filter(k => !commonSkip.includes(k));

	const dateFields = ['dueDate', 'statDate', 'endDate'];
	const skipFields = ['type'];

	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys1) {
		if (!keys2.includes(key)) {
			const value = obj1[key];
			if (!isEmptyValue(value)) {
				console.log(`Mismatch for key '${key}': Field missing in second object.`);
				return false;
			}
		} else {
			const value1 = obj1[key];
			const value2 = obj2[key];

			if (skipFields.includes(key)) continue;

			if (isEmptyValue(value1) && isEmptyValue(value2)) {
				continue;
			}

			if (typeof value1 === 'object' && typeof value2 === 'object' && value1 && value2) {
				if (!deepCompareWithConditions(value1, value2)) {
					console.log(`Mismatch in nested object for key '${key}'.`);
					return false;
				}
			} else if (dateFields.includes(key) && value1 && value2) {
 				if (!compareDate(value1, value2)) {
					console.log(`Mismatch for key '${key}': Date mismatch.`);
					return false;
				}
			} else if (value1 !== value2) {
				console.log(`Mismatch for key '${key}': '${value1}' !== '${value2}'.`);
				return false;
			}
		}
	}

	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys2) {
		if (!keys1.includes(key)) {
			const value = obj2[key];
			if (!isEmptyValue(value)) {
				console.log(`Mismatch for key '${key}': Field missing in first object.`);
				return false;
			}
		}
	}

	return true;
}

function compareDate(dateStr1, dateStr2) {
	const date1 = new Date(dateStr1);
	const date2 = new Date(dateStr2);

	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

function compareDateWithTime(dateStr1, dateStr2) {
	const date1 = new Date(dateStr1);
	const date2 = new Date(dateStr2);

	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate() &&
		date1.getHours() === date2.getHours() &&
		date1.getMinutes() === date2.getMinutes()
	);
}

function isEmptyValue(value) {
	return (
		value === null ||
		value === undefined ||
		value === false ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		value === '' ||
		value === ' ' ||
		value === 'false'
	);
}

export function generateUniqueRandomNumber() {
	const timestamp = new Date().getTime();
	const randomNumber = Math.floor(Math.random() * timestamp);
	return randomNumber;
}

export function getEmailFromString(inputString = '') {
	const pattern = /<([^>]+)>/; // Regular expression pattern to extract email
	const match = inputString.match(pattern);
	const email = match ? match[1] : '';
	return email;
}

export const getDropDownLabel = (option, field) => {
	if (!option || _.isString(option)) return option;
	const key = field || 'label';
	if (option.nameSuffix) {
	  return `${option[key]} (${option.nameSuffix})`;
	}
	return option[key];
};

export const getAssignedUserIds = (assignedUsersList, technicians) => {
	if(assignedUsersList?.length){
		const assignedUserIds = [];
		assignedUsersList?.forEach(assignedUser => {
			const user = technicians?.find(val=>val.technicianFullName === assignedUser);
			if(user?.technicianUserId) assignedUserIds.push(user.technicianUserId);
			else if(['Unassigned', 'Third Party', BlankGridFilterParamText].includes(assignedUser)) assignedUserIds.push(FILTER_VALUE_BACKEND_MAP[assignedUser]); 
			else assignedUserIds.push(assignedUser);
		});

		return assignedUserIds?.join(',');
	}
	return '';
};

export const getClientNameString = (clientNameArray) => {
	if(clientNameArray?.length){
		let clientNameString = clientNameArray?.join('|');
		clientNameString = _.replace(clientNameString, 'Unassigned', 'Blanks');
		return clientNameString;
	}
	return '';
};

export const getIdString = (data = []) => _.map(data, item => item.id).join(',')

export const getFrequencyTagString = (frequencyTagArr) => {
	if(frequencyTagArr?.length){
		return frequencyTagArr.join(',').toLowerCase();
	}
	return '';
};
