// task-board
export const TASKS = '/api/v1/tasks';
export const GET_TASKS = '/api/v1/tasks';
export const ADD_TASKS = '/api/v1/tasks';
export const UPDATE_TASK = '/api/v1/tasks';
export const UPDATE_STATUS_TASKS = '/api/v1/tasks/update-status';
export const VALIDATE_TASKS = '/api/v1/tasks/validate';
export const DELETE_MULTIPLE_TASKS = '/api/v1/tasks/delete-tasks';
export const GET_TASK_MASTER_DATA = '/api/v1/tasks/master-data';
export const GET_TASK_DAILY_COUNT = '/api/v1/tasks/daily-count';
export const MULTI_UPDATE_TASK = '/api/v1/tasks/update-tasks';
export const LOCATION_TASKS = '/api/v2/master-locations/tasks-map-view'
export const TASK_SUMMARY_DATA ='/api/v1/tasks/summary';
export const GET_TASK_DETAILS = '/api/v1/master-locations/tasks';
export const TASKS_PAGINATED ='/api/v2/master-locations/tasks';
export const TASK_CALIBRATION_STATUS ='/api/v1/formdata/field-items/status';
export const USER_FDC_FIELDS ='/api/v1/task/formdata/field-test-codes';
export const LIMS_FIELD_ITEMS = '/api/v1/sample/field-items';

// task-board locations
export const GET_TASK_LOCATIONS = '/api/v1/task-location';
export const ADD_TASK_LOCATIONS = '/api/v1/task-location';
export const UPDATE_TASK_LOCATIONS = '/api/v1/task-location';

// task customer
export const TASK_CLIENTS = '/api/v1/task-clients';
export const TASK_SCHEDULE = '/api/v1/task-schedule';
export const CLIENTS_COMMENTS = '/api/v1/business-entity';
export const BUS_ENTITY_NOTE_COUNT = '/api/v1/business-entity/notes-count';

// reoccurring config
export const REOCCURRING_CONFIG = '/api/v1/recurring-config';
export const GET_REOCCURRING_CONFIG_DETAILS = '/api/v1/master-locations/recurring-config';
export const RECURRING_CONFIG_PAGINATED = '/api/v2/master-locations/recurring-config';

export const ATTACHMENT = '/api/v1/attachment';
export const TASK_WITH_ATTACHMENT = '/api/v1/task-with-attachment';
export const REOCCURRING_CONFIG_WITH_ATTACHMENT = '/api/v1/recur-cfg-with-attachment';

// taskboard routine/lims
export const ROUTINE = '/api/v1/lds/routine'
export const SAMPLE_TESTS = '/api/v1/sample/tests'
export const SAMPLE_METRIX = '/api/v1/sample/matrix'
export const SAMPLE_CONTAINER = '/api/v1/sample/container'

// mdm
export const SAMPLE_LOC_WITH_ENTITIES = '/sample-location-with-entities'
export const GET_BUSINESS_ENTITY_EX = '/business-entity-ex';
export const GET_SAMPLE_LOCATION_EX = '/sample-location';
export const GET_LOCATION_EX = '/locations';
export const GET_EQUIPMENT_EX = '/equipment';
export const USER_EQUIPMENT = '/user/field-items';
export const EQUIPMENT_CALIBRATION = '/lab-equipment/calibration';
export const FIELD_ITEMS_VERIFY = '/user/field-items/verify';

// routes
export const ADD_ROUTE = '/api/v1/routes';
export const GET_ROUTES = '/api/v1/routes';
export const DELETE_ROUTE = '/api/v1/routes';
export const UPDATE_ROUTE = '/api/v1/routes';
export const DELETE_MULTIPLE_ROUTES = '/api/v1/routes/delete-routes';

// logistics
