/* eslint-disable import/prefer-default-export */
import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import { PROFILE_SIGNATURE, PROFILE_SIGNATURE_UPLOAD } from './urls';


export const uploadUserSignature = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/uploadUserSignature',
	async (params, thunkApi) => {
		try {
			const signature = await axios.post(
				`${BACKEND_SCHEDULER_URL}${PROFILE_SIGNATURE_UPLOAD}`,
				params.formData,
				{
					headers: {
						'Content-type': 'multipart/form-data'
					}
				}
			);
			thunkApi.dispatch(
				showMessage({
					message: `Signature uploaded successfully`,
					variant: 'success'
				})
			);
			thunkApi.dispatch(getUserSignatures());
			return signature;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getUserSignatures = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getUserSignatures',
	async (params, thunkApi) => {
		try {
			const signature = await axios.get(`${BACKEND_SCHEDULER_URL}${PROFILE_SIGNATURE}`);
            console.log("CC res: ", signature)
			return signature;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const downloadUserSignature = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/downloadUserSignature',
	async (params, thunkApi) => {
		try {
			const signature = await axios.get(`${BACKEND_SCHEDULER_URL}${PROFILE_SIGNATURE}/${params.id}/download`);
			return signature;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateSignatureName = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateSignatureName',
	async (params, thunkApi) => {
		try {
			const signature = await axios.put(`${BACKEND_SCHEDULER_URL}${PROFILE_SIGNATURE}/${params.id}`, params);
			return signature;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteSignature = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteSignature',
	async (params, thunkApi) => {
		try {
			const signature = await axios.delete(`${BACKEND_SCHEDULER_URL}${PROFILE_SIGNATURE}/${params.id}`);
			thunkApi.dispatch(getUserSignatures());
			return signature;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);